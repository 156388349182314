import { PARAMETER_LANGUAGE } from "src/Utilities/constants";


export function getUserLanguageFromBrowser() {
  const queryParams = new URLSearchParams(window.location.search);
  const parameterLanguage = queryParams.get(PARAMETER_LANGUAGE);
  const sessionLanguage = sessionStorage.getItem("userLanguage");
  const localLanguage = localStorage.getItem("userLanguage");
  let userLanguage="en";

  if(parameterLanguage) {
    userLanguage = parameterLanguage;
   //put another check as queryParams got lost when theres &goto=https://.....
  } else if(window.location.search && window.location.search.toLocaleLowerCase().indexOf("locale=fr")>0) {
    userLanguage="fr";
  } else if(window.location.search && window.location.search.toLocaleLowerCase().indexOf("locale%3dfr")>0) {
    userLanguage = "fr";
  } else if(window.location.search && window.location.search.toLocaleLowerCase().indexOf("locale=en")>0) {
    userLanguage="en";
  } else if(window.location.search && window.location.search.toLocaleLowerCase().indexOf("locale%3den")>0) {
    userLanguage = "en";
  } else if (localLanguage) {
    userLanguage = localLanguage;
  } else if (sessionLanguage) {
    userLanguage = sessionLanguage;
  } else if(navigator.language==="fr" || navigator.language==="fr-CA" ) {
    userLanguage = "fr";
  }
  sessionStorage.setItem("userLanguage", userLanguage);
  localStorage.setItem("userLanguage", userLanguage);
  return userLanguage;
}
