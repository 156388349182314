//JOURNEYS
export const JRN_LOGIN = process.env.REACT_APP_JRN_LOGIN;
export const JRN_COMMUNITY_LOGIN = process.env.REACT_APP_JRN_LOGIN_COMMUNITY;

export const JRN_REGISTER = process.env.REACT_APP_JRN_REGISTER;
export const JRN_REGISTER_COMMUNITY =
  process.env.REACT_APP_JRN_REGISTER_COMMUNITY;
export const JRN_CHANGE_EMAIL = process.env.REACT_APP_JRN_CHNG_EMAIL;
export const JRN_CHANGE_PHONE = process.env.REACT_APP_JRN_CHNG_PHONE;
export const JRN_CHANGE_PASSWORD = process.env.REACT_APP_JRN_CHNG_PASS;
export const JRN_CHANGE_NAME = process.env.REACT_APP_JRN_CHNG_NAME;
export const JRN_FORGOT_PASSWORD = process.env.REACT_APP_JRN_FRGT_PASS;
export const JRN_ACTIVATE_PHONE = process.env.REACT_APP_JRN_ACTIVATE_PHONE;
export const JRN_FORGOT_EMAIL_COMMUNITY =
  process.env.REACT_APP_JRN_FRGT_EMAIL_COMMUNITY;
export const JRN_FORGOT_EMAIL = process.env.REACT_APP_JRN_FRGT_EMAIL;
export const JRN_LOGIN_COMM_K6 = process.env.REACT_APP_JRN_LOGIN_COMM;
export const JRN_RCVR_EMAIL_LOGIN = process.env.REACT_APP_JRN_RCVR_EMAIL_LOGIN;
export const JRN_RCVR_EMAIL_LOGIN_COMMUNITY =
  process.env.REACT_APP_JRN_RCVR_EMAIL_LOGIN_COMMUNITY;
export const JRN_RETRYTEST_EMAIL = process.env.REACT_APP_JRN_RETRYTEST_EMAIL;
export const REACT_APP_JRN_LOA2 = process.env.REACT_APP_JRN_LOA2;
export const REACT_APP_JRN_LOA2_PM = process.env.REACT_APP_JRN_LOA2_PM;
export const REACT_APP_JRN_LOA_KHOROS = process.env.REACT_APP_JRN_LOA_KHOROS;

// OTHERS
export const AM_URL = process.env.REACT_APP_AM_URL;
export const API_URL = process.env.API_URL;
export const SESSION_URL = `${AM_URL}json/realms/root/sessions`; // Un-used but would be missing a / before json
export const AM_AUTHORIZE_URL = `${AM_URL}/oauth2/realms/root/realms/alpha/authorize`;

// INITIAL CONFIGURATION
export const INITIAL_CONFIG = {
  clientId: process.env.REACT_APP_CONFIG_CLIENT_ID,
  redirectUri: process.env.REACT_APP_CONFIG_REDIRECT_URI,
  scope: process.env.REACT_APP_CONFIG_SCOPE,
  serverConfig: {
    baseUrl: process.env.REACT_APP_CONFIG_URL,
    timeout: process.env.REACT_APP_CONFIG_TIMEOUT,
  },
  realmPath: process.env.REACT_APP_CONFIG_REALM,
  tree: process.env.REACT_APP_CONFIG_TREE,
};

// URL PARAMETER NAMES
// this allows us to avoid hardcoding the params and use a single place to update
// may help if we are asked to change from "origin" to "portal" or "ln" to "language"
export const PARAMETER_ORIGIN = process.env.REACT_APP_URL_PARAM_ORIGIN;
export const PARAMETER_LANGUAGE = process.env.REACT_APP_URL_PARAM_LANGUAGE;
export const PARAMETER_SCOPE = process.env.REACT_APP_URL_PARAM_SCOPE;
export const PARAMETER_CLIENT_ID = process.env.REACT_APP_URL_PARAM_CLIENT_ID;
export const PARAMETER_SUCCESS_URL =
  process.env.REACT_APP_URL_PARAM_SUCCESS_URL;
export const PARAMETER_REDIRECT_URI =
  process.env.REACT_APP_URL_PARAM_REDIRECT_URI;
export const PARAMETER_ID_TOKEN = process.env.REACT_APP_URL_PARAM_ID_TOKEN;

export const ICON_ARROW = "IconArrow";
export const BACK = "Back";
export const K1Login = "K1-Login";
export const K3Login = "K3-Login";
export const R1InitiateRegistration = "F1_1_of_4";
export const S1Credentials = "F2_1_of_5";
export const ChangeEmail = "Change email";
export const Q1 = "Q1_3Q2_1_of_2";
export const F1_4 = "F1_4_of_4";
export const F1_1_of_4 = "F1_1_of_4";
export const F1_4_of_4 = "F1_4_of_4";
export const Q1_2_1_of_2 = "Q1_2_1_of_2";
export const Q1_3Q2_1_of_2 = "Q1_3Q2_1_of_2";
export const N1N2_1_of_3 = "N1N2_1_of_3";
export const N1N2_1_of_3_email = "N1N2_1_of_3_email";
export const N1N2_1_of_3_phone = "N1N2_1_of_3_phone";
export const R1InitiateReistration = "F1_1_of_4";
export const Q1ChangeName = "Q1_1_of_1";
export const EnterFNameLName = "EnterFnameLname";
export const S3EnterPassword = "S-3 enter password";
export const G1FnameLname = "G1-FnameLname";
export const S5EnterProfile = "F2_4_of_5";
export const S5TelephoneNumber = "S-5 telephoneNumber";
export const O1EnterUserPhoneNumber = "o1-enterUserphonenumber";
export const OTPCBE = "OTPCB-E";
export const OTPCBS = "OTPCB-S";
export const OTPCBV = "OTPCB-V";
export const OTPCBMS = "OTPCB-MS";
export const M3_3_of_3 = "M3_3_of_3";

//VALIDATIONS
export const FNAME_MAX_LENGTH=20;
export const LNAME_MAX_LENGTH=13;
export const FNAME_VALID_CHAR_REGEX = /^[A-Za-zÁÉÍÓÚÝÀÈÌÒÙÊÎÔÛÄËÏÖÜÇáéíóúýàèìòùêîôûäëïöüç '.-\s!"#%&'()*+,-./:;?@[\]^_`]*$/;
export const LNAME_VALID_CHAR_REGEX = /^[A-Za-zÁÉÍÓÚÝÀÈÌÒÙÊÎÔÛÄËÏÖÜÇáéíóúýàèìòùêîôûäëïöüç '.-\s!"#%&'()*+,-./:;?@[\]^_`]*$/;
export const WHITELIST_URL_REGEX = /.*telus.com.*|.*publicmobile.ca.*|.*btln001281.corp.ads.*/;

//ICR 28: support content in footer
export const SUPPORT_FOOTER_EN = "Click <font color='blue'><a href='https://publicmobile.ca/en/get-help/articles/eversafe-faqs'>here</a></font> for troubleshooting tips and support.";
export const SUPPORT_FOOTER_FR = "Clique <font color='blue'><a href='https://publicmobile.ca/fr/get-help/articles/eversafe-faqs'>ici</a></font> pour obtenir des conseils de dépannage et du soutien.";

//UI TEXT
export const UNKNOWN_REQUEST_EN = "Unknown request failure";
export const UNKNOWN_REQUEST_FR = "Échec de la demande inconnue";

//REDIRECTS
export const LOA2PM_FAILURE_REDIRECT = "com.telus.publicmobile.dev.auth:/oauthredirect";

//FLOW CONSTANTS LOGIN EVENT
export const LOGIN_EVENT = ['login'];
//FLOW CONSTANTS CHANGE EVENTS
export const CONFIRMATION_CHANGE_EVENTS = ['change_name', 'change_password', 'change_email', 'change_phone'];
export const CHANGE_EVENTS = {
  change_name: 'name',
  change_password: 'password',
  change_email: 'email',
  change_phone: 'phone'
};
