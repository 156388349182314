import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FRWebAuthn,
  FRAuth,
  FRDevice,
  TokenStorage,
  UserManager,
} from "@forgerock/javascript-sdk";
import {
  PARAMETER_ORIGIN,
  PARAMETER_LANGUAGE,
  PARAMETER_SCOPE,
  PARAMETER_CLIENT_ID,
  PARAMETER_SUCCESS_URL,
  PARAMETER_REDIRECT_URI,
} from "src/Utilities/constants";
import alloyAnalytics from "@telus/alloy-analytics";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [isAuthenticated, setAuthentication] = useState(false);
  const [email, setEmail] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState("");
  const [step, setStep] = useState(null);
  const [screen, setScreen] = useState(1);
  const [userLanguage, setUserLanguage] = useState("en");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  //const [origin, setOrigin] = useState("");
  const [token, setToken] = useState(null);
  const [otpCode, setOtpCode] = useState("");
  const [otpState, setOtpState] = useState("");
  const [isOtpFailed, setIsOtpFailed] = useState(false);
  const [isCommunity, setIsCommunity] = useState(false);
  const [brand, setBrand] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [recoverPhone, setRecoverPhone] = useState("");
  const [recoverEmail, setRecoverEmail] = useState("");
  const [recoverUsername, setRecoverUsername] = useState("");
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [cfg, setCfg] = useState({
    clientId: process.env.REACT_APP_CONFIG_CLIENT_ID,
    redirect: process.env.REACT_APP_CONFIG_REDIRECT_URI,
    scope: process.env.REACT_APP_CONFIG_SCOPE,
    journey: process.env.REACT_APP_CONFIG_TREE,
  });
  const [resetTimer, setResetTimer] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [checkedSC, setCheckedSC] = useState(false);
  const [validEmailUser, setValidEmailUser] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [termsCBState, setTermsCBState] = useState(false);
  const [previousFName, setPreviousFName] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [oldPhone, setOldPhone] = useState("");
  const [isRedirect, setIsRedirect] = useState(true);
  const [timer, setTimer] = useState(300);

  const navigate = useNavigate();

  //Getting params from URL
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);

    // If goto url present parse cfg off of goto query params
    if (queryParams.get("goto")) {
      const gotoUrl = new URL(queryParams.get("goto"));
      queryParams = new URLSearchParams(gotoUrl.search);
    }

    // setOrigin(queryParams.get(PARAMETER_ORIGIN));
    let existingOrigin = queryParams.get(PARAMETER_ORIGIN);
    setIsCommunity(existingOrigin == "community" ? true : false);
    setBrand(existingOrigin);

    //const clientId = queryParams.get(PARAMETER_CLIENT_ID);
    const scope = queryParams.get(PARAMETER_SCOPE);
    const redirect = queryParams.get(PARAMETER_REDIRECT_URI);
    let language = queryParams.get(PARAMETER_LANGUAGE);
    //put another check as queryParams got lost when theres &goto=https://.....
    if(!language && window.location.search.toLocaleLowerCase().indexOf("locale=fr")>0) {
      language="fr";
    }


    const sessionLanguage = sessionStorage.getItem("userLanguage");
    const localLanguage = localStorage.getItem("userLanguage");

    if (localLanguage) {
      setUserLanguage(localLanguage);
    } else if (sessionLanguage) {
      setUserLanguage(sessionLanguage);
    } else if (!language && userLanguage) {
      setUserLanguage(userLanguage);
    } else if(!userLanguage && window.location.search && window.location.search.toLocaleLowerCase().indexOf("locale%3dfr")>0) {
      setUserLanguage("fr");
    } else {
      setUserLanguage(language);
    }

    //setUserLanguage(language ? language : "en");
    //TODO: parse nonce with env var const
    const nonce = queryParams.get("nonce");
    //TODO: parse state with env var const
    const state = queryParams.get("state");
    setCfg({
      clientId: process.env.REACT_APP_CONFIG_CLIENT_ID,

      redirect: redirect === null ? "dashboard" : redirect,
      scope: scope === null ? process.env.REACT_APP_CONFIG_SCOPE : scope,
      // TODO: set nonce + env default
      nonce,
      // TODO: set state + env default
      state,
    });
  }, []);

  useEffect(() => {
    //console.log("Current Config Values: ", cfg);
  }, [cfg]);

  useEffect(() => {
    alloyAnalytics.config({
      setup: {
        domain: 'telus',
      },
      defaultValues: {
        webPageDetails: { 
          lob: 'public_mobile' 
        }
      } 
    });
}, []);

  // These functions allow for additional adjustments to be made to the state updates in subsidiary pages. E.g. for setEmail & setUser, these functions allow us to grab user info from browser session storage.
  async function setTokenWrapper(value) {
    TokenStorage.set({ idToken: value });
    window.sessionStorage.setItem(
      `${process.env.REACT_APP_CONFIG_COOKIE_NAME}`,
      `${value}`
    );
    setAuthToken(value);
  }

  async function setAuthenticationWrapper(value) {
    setAuthentication(value);
  }
  function setEmailWrapper(value) {
    window.sessionStorage.setItem("sdk_email", `${value}`);
    setEmail(value);
  }
  function setUserWrapper(value) {
    window.sessionStorage.setItem("sdk_username", `${value}`);
    setUsername(value);
  }

  const appValue = {
    isAuthenticated,
    setAuthentication: setAuthenticationWrapper,
    setToken: setTokenWrapper,
    email,
    setEmail: setEmailWrapper,
    updatedEmail,
    setUpdatedEmail,
    username,
    setUsername,
    phone,
    setPhone,
    userId,
    setUserId,
    setUser: setUserWrapper,
    setUserWrapper,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    step,
    setStep,
    screen,
    setScreen,
    userLanguage,
    setUserLanguage,
    cfg,
    setCfg,
    otpCode,
    otpState,
    setOtpState,
    setOtpCode,
    isOtpFailed,
    setIsOtpFailed,
    isCommunity,
    setIsCommunity,
    disableSubmitBtn,
    setDisableSubmitBtn,
    recoverPhone,
    setRecoverPhone,
    recoverEmail,
    setRecoverEmail,
    isLoginSuccess,
    setIsLoginSuccess,
    recoverUsername,
    setRecoverUsername,
    resetTimer,
    setResetTimer,
    passwordLength,
    setPasswordLength,
    checkedSC,
    setCheckedSC,
    validEmailUser,
    setValidEmailUser,
    validUser,
    setValidUser,
    passwordModalOpen,
    setPasswordModalOpen,
    selectedTab,
    setSelectedTab,
    termsCBState,
    setTermsCBState,
    previousFName,
    setPreviousFName,
    oldEmail,
    setOldEmail,
    oldPhone,
    setOldPhone,
    isRedirect,
    setIsRedirect,
    timer,
    setTimer,
    brand,
    setBrand,
  };

  return (
    <AuthContext.Provider value={appValue}>{children}</AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
