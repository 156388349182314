// React-specific & utility imports
import { ActivityIndicator } from "@telus-uds/components-base";

// Displays the Loading spinner
export default function Spinner({ message }) {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col justify-center items-center h-full gap-4">
        <div className="w-8 lg:w-11 spinner" style={{ align: "center" }}>
          <ActivityIndicator label="Loading" />
        </div>
        <div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
